<template>
    <div v-if="downloads.data">
        <section id="download" class="download section-bg">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>{{ $t('Mobile App') }}</h2>
                    <h3>{{ $t('DOWNLOAD OUR MOBILE APP NOW!') }}</h3>
                </div>

                <div class="row" v-if="downloads.data">
                    <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
                        <img :src="resource+downloads.data.photo" class="img-fluid" alt="">
                        <div class="row mt-3">
                            <div class="col-sm-6 align-items-center justify-content-center section-title">
                                <br>
                               <h2 class="mt-2"> {{ $t('Download our mobile app on') }}</h2>
                            </div>
                            <div class="col-sm-6 d-flex align-items-center  justify-content-center">
                                <img :src="resource +downloads.data.ios_icon" class="w-50" alt="">
                                <img :src="resource+downloads.data.android_icon" class="w-50" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                        <h3>{{ this.$i18n.locale=='en' ? downloads.data.name : downloads.data.name_kh }}</h3>
                        <ul>
                            <li v-for="(d, index) in downloads.items"  :key="index">
                                
                                <div class="d-flex align-items-center justify-content-center">
                                    <img :src="resource + d.photo" alt="Icon" width="50">
                                    <p class="ms-3">{{ this.$i18n.locale == 'en' ? d.description : d.description_kh }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    downloads : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>

<style>

</style>