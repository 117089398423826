<template>
  <div>
      <section id="values" class="values">
          <div class="container" data-aos="fade-up">
  
              <div class="section-title">
                  <h2>{{ $t('Our Value') }}</h2>
                  <h3>{{ $t('Simplify your business operations with VPOS.CLUB - your trusted partner for free, powerful POS solutions and premium digital services.') }}</h3>
              </div>
  
              <div class="row">
                  <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100" v-for="(d, index) in values"  :key="index">
                      <div class="icon-box w-100">
                          <div class="icon"><i :class="d.photo"></i></div>
                          <h4><a href="">{{ this.$i18n.locale == 'en' ? d.name : d.name_kh }}</a></h4>
                          <p>
                              {{ this.$i18n.locale == 'en' ? d.description : d.description_kh }}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
  </template>
  

  <script>
  import {resource} from '@/networks/domain'

  export default {
    props : {
      values : {
        type : [Object, Array],
        default : []
      }
    },
    
    data(){
      return {
        resource : resource
      }
    },
   
    
  }
  </script>
  
  <style>
  
  </style>