<script>
export default {
    props : {
      showText : {
        type : [Boolean],
        default : []
      }
    },
    data(){
      return {
        language : 'en',
      }
    },
    methods : {
      checkLanguage(){
        if(!localStorage.language){
          localStorage.language = this.language;
        } else {
          this.language = localStorage.language;
        }
      },
      changeLanguage(lang){
        this.$i18n.locale = lang;
        localStorage.language = lang;
        this.language = localStorage.language
        $('#language').trigger('click');
      },
    },
    mounted() {
      this.checkLanguage()
    }
};
</script>

<template>
    <li class="nav-item dropdown" id="mobile_dropdown_menu">
      <a class="nav-link" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <div v-if="language == 'en'" class="nav-link" data-toggle="dropdown">
          <img src="assets/img/english.png" alt="" width="30" class="pb-1"> <span v-if="showText">{{ $t('English') }}</span>
        </div>
        <div v-else class="nav-link" data-toggle="dropdown">
          <img src="assets/img/khmer.png" alt="" width="30" class="pb-1"> <span v-if="showText">{{ $t('Khmer') }}</span>
        </div>
      </a>
      <ul class="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
        <li @click="changeLanguage('kh')" class="dropdown-item text-dark" style="cursor: pointer;">
            <img src="assets/img/khmer.png" alt="" width="20" class="pb-1"> {{ $t('Khmer') }}
        </li>
        <li @click="changeLanguage('en')" class="dropdown-item text-dark" style="cursor: pointer;">
            <img src="assets/img/english.png" alt="" width="20" class="pb-1"> {{ $t('English') }}
        </li>
      </ul>
    </li>
</template>

<style scoped>
  .navbar .dropdown ul li {
    min-width: 100px;
  }
  .navbar .dropdown ul[data-v-3ed892dc] {
    display: block;
    position: absolute;
    left: -30px;
    top: calc(100% + 10px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    /* opacity: 9999;
    visibility: visible !important; */
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

</style>
