<template>
    <section class="container rounded">
      <br><br>
      <div id="carouselExampleCaptions" data-interval="200" class="carousel slide" data-bs-ride="true">
      <!-- <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div> -->
      <div class="carousel-inner">
        <template v-for="( data , index) in slideshows" :key="data.id">
          <!-- <a :href="data.link" target="_blank"> -->
            <div :class="`carousel-item ${index == 0 ? 'active' : ''}`">
              <img :src="resource + data.photo" class="d-block w-100 rounded" alt="slide">
            </div>
          <!-- </a> -->
        </template>
      </div>
      <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> -->
    </div>
    </section>
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    slideshows : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  },
  mounted(){

  }
}
</script>

<style>

</style>