<template>
    <div>
        
        <main id="main" class="mt-5">
            <br>
            <div class="mobile_search">
                <SearchForm/>
            </div>
            <div v-if="!isLoad" class="container vh-100 position-relative fake-slide mb-5">
            <h1 class="text-center text-success">Please wait...</h1>
            </div>
            <div v-if="isLoad" class="container py-5">
                <div class="row" v-if="result">
    
                    <div class="col-md-12 col-lg-12">
                        <di id="tracking-pre">
                            <h3 class="text-color"><strong>{{ $t('TRACKING NUMBER') }}: {{result.track_code}}</strong></h3>
                            <p><strong>{{ $t('Route') }}:</strong> {{ $t(result.from_country) }} - {{ $t(result.to_country) }}</p>
                            <p><strong>{{ $t('Weight') }}:</strong> {{ result.size + result.product_size_name }}</p>
                            <h4 class="text-dark"><strong>{{ $t('CHECKPOINT') }}</strong>:</h4>
                        </di>
                        <div id="tracking">
                            <div class="tracking-list">
                                <div :class="(result.status_id >= 1) ? 'tracking-item' : 'tracking-item-pending'">
                                    <div class="tracking-icon" :class="(result.status_id == 1 ) ? 'status-current blinker' : 'status-intransit'">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date"><img :src="'assets/img/delivery_process_done.png'" width="40" alt="Delivered icon"></div>
                                    <div class="tracking-content">{{ result.from_country == 'Thailand' ? $t('In Thailand Warehouse') : result.from_country == 'Vietnam' ? $t('In Vietnam Warehouse') : result.from_country == 'China' ? $t('In China Warehouse') : ($t(result.from_country)+ ' ' + $t('Warehouse'))}}<span>{{ formatDate(result.entered_date) }}</span></div>
                                </div>
                                <div :class="result.status_id >= 2 ? 'tracking-item' : 'tracking-item-pending'">
                                    <div class="tracking-icon" :class="(result.status_id == 2 || result.status_id == 3) ? 'status-current blinker' : 'status-intransit'">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date"><img :src="'assets/img/delivery_process_done.png'" width="40" alt="Delivered icon"></div>
                                    <div class="tracking-content">{{ $t('In Transit') }}<span>{{ result.transit_date ? formatDate(result.transit_date) : "" }}</span></div>
                                </div>
                                <div :class="result.status_id >= 4 ? 'tracking-item' : 'tracking-item-pending'">
                                    <div class="tracking-icon" :class="(result.status_id == 4) ? 'status-current blinker' : 'status-intransit'">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date"><img :src="'assets/img/delivery_process_done.png'" width="40" alt="Delivered icon"></div>
                                    <div class="tracking-content">{{ $t('Arrived KH') }}<span>{{ result.customer_type_id != 3 && result.arrived_date ? (result.arrived_date ? formatDate(result.arrived_date): '') : result.created_at ? formatDate(result.created_at) : '' }}</span></div>
                                </div>
    
                                <div :class="result.status_id >= 5 ? 'tracking-item' : 'tracking-item-pending'" v-if="result.delivery_type_id == 2">
                                    <div class="tracking-icon" :class="(result.status_id == 5) ? 'status-current blinker' : 'status-intransit'">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date"><img :src="'assets/img/delivery_process_done.png'" width="40" alt="Delivered icon"></div>
                                    <div class="tracking-content">{{ $t('Preparing for Delivery') }}<span>{{result.created_at ? formatDate(result.created_at) : '' }}</span></div>
                                </div>
    
                                <div :class="result.status_id >= 6 ? 'tracking-item' : 'tracking-item-pending'" v-if="result.delivery_type_id == 2">
                                    <div class="tracking-icon" :class="(result.status_id == 6) ? 'status-current blinker' : 'status-intransit'">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date"><img :src="'assets/img/delivery_process_done.png'" width="40" alt="Delivered icon"></div>
                                    <div class="tracking-content">{{ $t('On Delivery') }}<span>{{result.date_assign_to_delivery ? formatDate(result.date_assign_to_delivery) : '' }}</span></div>
                                </div>
                                <div :class="result.status_id >= 7 ? 'tracking-item' : 'tracking-item-pending'">
                                    <div class="tracking-icon" :class="(result.status_id == 7) ? 'status-current blinker' : 'status-intransit'">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date">
                                        <img :src="'assets/img/done.png'" width="40" class="rounded-circle" alt="Delivered icon">
                                    </div>
                                    <div class="tracking-content">{{ $t('Delivered') }}<span>{{result.delivery_type_id != 2 ? (result.created_at ? formatDate(result.created_at) : '' ) : result.date_completed_delivery ? formatDate(result.date_completed_delivery) : '' }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="text-center text-danger" v-if="!result">
                            {{ $t('We could not find your tracking!') }}
                        </h1>
                    </div>
                </div>
            </div>
    
        </main><!-- End #main -->
    
        <!-- ======= Footer ======= -->
        <Footer :data="data.contact" :socials="data.social" />
    
        <!-- End Footer -->
    
    </div>
    </template>
    
    <script>
    // @ is an alias to /src
    import Footer from '@/components/Footer'
    import SearchForm from '@/components/SearchForm'
    
    import axios from 'axios'
    import { all } from '@/networks/all/index.service'
    import { search } from '@/networks/search/index.service'
    // import { loadJs } from '@/components/inc/everythingJS/until'
    import {resource} from '@/networks/domain'
    
    export default {
      name: 'SearchView',
      components: {
        Footer,
        SearchForm
      },
      data(){
        return {
          data : [],
          result : [],
          isLoad : false,
          resource : resource,
          tracking: this.$route.query.code
        }
      },
      methods:{
        async getData(){
          axios.get(all)
            .then((res)=>{
              if(res.status == 200){
                this.data = res.data.data;
                this.about = res.data.data.page.about
                this.isLoad = true;
              }
            })
            .then(()=>{
              $('html, body').animate({
                scrollTop: $(`#${this.whereIs}`).offset().top - 100
              }, 20);
            })
            .catch((err) =>{
              console.log(err);
            })
        },
        async getResult(){
            axios.get(search+this.tracking+'/webetipol20234')
            .then((res)=>{
              if(res.status == 200){
                this.result = res.data.result;
              }
            })
            .catch((err) =>{
              console.log(err);
            })
        },
        formatDate(time) {
            const date = new Date(time);
            // Get year, month, and day part from the date
            const year = date.toLocaleString("default", {
                year: "numeric"
            });
            const month = date.toLocaleString("default", {
                month: "2-digit"
            });
            const day = date.toLocaleString("default", {
                day: "2-digit"
            });
            return `${day}-${month}-${year}, ${date.toLocaleTimeString()}`;
        }
      },
      async created(){
        await this.getData();
        await this.getResult();
      },
      mounted(){
        // this.getData();
      },
      updated(){
        
      }
    }
    </script>
    <style scoped>
    .mobile_search {
        /* display: flex !important; */
        /* justify-content: center !important; */
        align-items: center !important;
        margin-top: 40px;
    }
    
    .search_box {
        width: 200px !important;
    }
    
    .tracking-detail {
        padding: 3rem 0;
    }
    
    #tracking {
        margin-bottom: 1rem;
    }
    
    [class*="tracking-status-"] p {
        margin: 0;
        font-size: 1.1rem;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }
    
    [class*="tracking-status-"] {
        padding: 1.6rem 0;
    }
    
    .tracking-list {
        /* border: 1px solid #11aabb; */
        border: 5px outset #11aabb;
        border-radius: 15px;
    
    }
    
    .tracking-item {
        border-left: 4px solid #00ba0d;
        position: relative;
        padding: 2rem 1.5rem 0.5rem 2.5rem;
        font-size: 0.9rem;
        margin-left: 3rem;
        min-height: 5rem;
    }
    
    .tracking-item:last-child {
        padding-bottom: 4rem;
    }
    
    .tracking-item .tracking-date {
        margin-bottom: 0.5rem;
    }
    
    .tracking-item .tracking-date span {
        color: #11aabb;
        font-size: 85%;
        padding-left: 0.4rem;
    }
    
    .tracking-item .tracking-content {
        padding: 0.5rem 0.8rem;
        background-color: #f4f4f4;
        border-radius: 0.5rem;
    }
    
    .tracking-item .tracking-content span {
        display: block;
        color: #767676;
        font-size: 13px;
    }
    
    .tracking-item .tracking-icon {
        position: absolute;
        left: -0.7rem;
        width: 1.1rem;
        height: 1.1rem;
        text-align: center;
        border-radius: 50%;
        font-size: 1.1rem;
        background-color: #fff;
        color: #fff;
    }
    
    .tracking-item-pending {
        border-left: 4px solid #d6d6d6;
        position: relative;
        padding: 2rem 1.5rem 0.5rem 2.5rem;
        font-size: 0.9rem;
        margin-left: 3rem;
        min-height: 5rem;
    }
    
    .tracking-item-pending:last-child {
        padding-bottom: 4rem;
    }
    
    .tracking-item-pending .tracking-date {
        margin-bottom: 0.5rem;
    }
    
    .tracking-item-pending .tracking-date span {
        color: #888;
        font-size: 85%;
        padding-left: 0.4rem;
    }
    
    .tracking-item-pending .tracking-content {
        padding: 0.5rem 0.8rem;
        background-color: #f4f4f4;
        border-radius: 0.5rem;
    }
    
    .tracking-item-pending .tracking-content span {
        display: block;
        color: #767676;
        font-size: 13px;
    }
    
    .tracking-item-pending .tracking-icon {
        line-height: 2.6rem;
        position: absolute;
        left: -0.7rem;
        width: 1.1rem;
        height: 1.1rem;
        text-align: center;
        border-radius: 50%;
        font-size: 1.1rem;
        color: #d6d6d6;
    }
    
    .tracking-item-pending .tracking-content {
        font-weight: 600;
        font-size: 17px;
    }
    
    .tracking-item .tracking-icon.status-current {
        width: 1.9rem;
        height: 1.9rem;
        left: -1.1rem;
    }
    
    .tracking-item .tracking-icon.status-intransit {
        color: #00ba0d;
        font-size: 0.6rem;
    }
    
    .tracking-item .tracking-icon.status-current {
        color: #00ba0d;
        font-size: 0.6rem;
    }
    
    @media (min-width: 992px) {
        .tracking-item {
            margin-left: 10rem;
        }
    
        .tracking-item .tracking-date {
            position: absolute;
            left: -10rem;
            width: 7.5rem;
            text-align: right;
        }
    
        .tracking-item .tracking-date span {
            display: block;
        }
    
        .tracking-item .tracking-content {
            padding: 0;
            background-color: transparent;
        }
    
        .tracking-item-pending {
            margin-left: 10rem;
        }
    
        .tracking-item-pending .tracking-date {
            position: absolute;
            left: -10rem;
            width: 7.5rem;
            text-align: right;
        }
    
        .tracking-item-pending .tracking-date span {
            display: block;
        }
    
        .tracking-item-pending .tracking-content {
            padding: 0;
            background-color: transparent;
        }
    }
    
    .tracking-item .tracking-content {
        font-weight: 600;
        font-size: 17px;
    }
    
    .blinker {
        border: 7px solid #e9f8ea;
        animation: blink 1s;
        animation-iteration-count: infinite;
    }
    
    @keyframes blink {
        50% {
            border-color: #fff;
        }
    }

    
    </style>
    