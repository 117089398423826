<template>
<div>
    <footer id="footer" v-if="data">
        <div class="footer-top">
            <div class="container">
                <div class="row">

                    <div class="col-lg-3 col-md-6 footer-contact">
                        <h3>{{ this.$i18n.locale == 'en' ? data.name : data.name_kh }}</h3>
                        <p>{{ this.$i18n.locale == 'en'? data.description_en :  data.description }}</p>
                        <p>
                            <span>{{  this.$i18n.locale == 'en'? data.address_en :  data.address }}</span><br>
                            <strong>{{ $t('Phone') }}:</strong> {{ data.phone }}<br>
                            <strong>{{ $t('Email') }}:</strong> {{ data.email }}<br>
                        </p>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>{{ $t('Useful Links') }}</h4>
                        <ul>
                            <li><i class="bx bx-chevron-right"></i> 
                                <router-link to="/">{{$t('Home')}}</router-link>
                            </li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="/about">{{ $t('About us') }}</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#services">{{ $t('Services') }}</a></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="/privacy">{{ $t('Privacy') }}</router-link></li>
                            <li><i class="bx bx-chevron-right"></i> <router-link to="/termsandcondition">{{ $t('Terms and Condition') }}</router-link></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>{{ $t('Our Services') }}</h4>
                        <ul>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ $t('System Solution') }}</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ $t('Website Development') }}</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ $t('Mobile Development') }}</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ $t('Hosting Service') }}</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">{{ $t('Hardware Supply') }}</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>{{ $t('Our Social Networks') }}</h4>
                        <p>{{ $t('Connect with us via official social media') }}</p>
                        <div class="social-links mt-3">
                            <span v-for="(d, index) in socials"  :key="index">
                                <a :href="d.link" target="_blank">
                                    <img :src="resource+d.photo" alt="" width="20" class="rounded-circle">
                                </a>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="container py-4">
            <div class="copyright">
                &copy; {{ $t('Copyright') }} <strong><span>{{ $t('VPOS.CLUB') }}</span></strong> | {{ $t('All Rights Reserved') }}
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    data : {
      type : [Object, Array],
      default : []
    },
    socials : {
      type : [Object, Array],
      default : []
    },
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>
