<template>
  <div>
      <div id="contact" class="contact">
          <div class="container" data-aos="fade-up">
  
              <div class="section-title">
                  <h3><span>{{ $t('Contact Us') }}</span></h3>
                  <p>{{ $t('We are here to help! Whether you have questions about our services, need technical support, or want to learn more about how VPOS.CLUB can benefit your business, our team is ready to assist you.')}}</p>
              </div>
  
              <div class="row" data-aos="fade-up" data-aos-delay="100">
                  <div class="col-lg-6">
                      <div class="info-box mb-4">
                          <i class="bx bx-map"></i>
                          <h3>{{ $t('Our Address') }}</h3>
                          <p>{{ this.$i18n.locale=='en' ? contact.address_en : contact.address }}</p>
                      </div>
                  </div>
  
                  <div class="col-lg-3 col-md-6">
                      <div class="info-box  mb-4">
                          <i class="bx bx-envelope"></i>
                          <h3>{{ $t('Email Us') }}</h3>
                          <p>{{ contact.email }}</p>
                      </div>
                  </div>
  
                  <div class="col-lg-3 col-md-6">
                      <div class="info-box  mb-4">
                          <i class="bx bx-phone-call"></i>
                          <h3>{{ $t('Call Us') }}</h3>
                          <p>{{ contact.phone }}</p>
                      </div>
                  </div>
  
              </div>
  
              <div class="row" data-aos="fade-up" data-aos-delay="100">
  
                  <!-- <div class="col-lg-12 ">
                      <div v-html="contact.google_map"></div>
                  </div> -->
  
              </div>
  
          </div>
      </div>
  </div>
  </template>
  
  <script>
  import {resource} from '@/networks/domain'
  export default {
    props : {
      contact : {
        type : [Object, Array],
        default : []
      }
    },
    data(){
      return {
        resource : resource
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  
  </style>
  