<template>
    <div>
        <section id="services" class="services" style="margin-top: -50px;">
            <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2>{{ $t('Services') }}</h2>
                    <h3>{{ $t('Check our Services') }}</h3>
                </div>
    
                <div class="row">
                    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" v-for="(d, index) in services"  :key="index">
                        <div class="icon-box w-100" data-aos="fade-up" data-aos-delay="100">
                            <div class="icon"><i :class="d.icon_name"></i></div>
                            <h4 class="title"><a href="">{{ this.$i18n.locale=='en'? d.name : d.name_kh }}</a></h4>
                            <p class="description">{{ this.$i18n.locale=='en'? d.description : d.description_kh }}</p>
                        </div>
                    </div>
                </div>
    
            </div>
        </section>
    </div>
    </template>
    
    <script>
    import {resource} from '@/networks/domain'
    export default {
      props : {
        services : {
          type : [Object, Array],
          default : []
        }
      },
      data(){
        return {
          resource : resource
        }
      }
     
    }
    </script>
    
    <style lang="scss" scoped>
    
    </style>
    