<template>
    <div>
      <header id="header" class="d-flex align-items-left fixed-top">
          <div class="container d-flex align-items-center justify-content-between">
              <i class="bi bi-list mobile-nav-toggle" id="mobile_munu"></i>

              <a class="logo" href="/"><img :src="'/assets/img/vpos-logo-landscap-no-bg.png'" alt="Logo"></a>
              <!-- search -->
              <nav id="navbar" class="navbar">
                  <ul>
                      <li>
                          <router-link to="/" :class="`${$route.name == 'home' ? 'active' : ''}`">{{$t('Home')}}</router-link>
                      </li>
                      <li>
                        <router-link to="/about" :class="`${$route.name == 'about' ? 'active' : ''}`">{{$t('About')}}</router-link>
                      </li>
                      <li>
                        <router-link to="/contact" :class="`${$route.name == 'contact' ? 'active' : ''}`">{{$t('Contact')}}</router-link>
                      </li>
                      <li><SwitchLanguage :showText="true"/></li>
                  </ul>
                 

              </nav><!-- .navbar -->
              <ul style="list-style-type: none;" class="d-sm-none d-block pt-3">
                <SwitchLanguage :showText="false"/>
              </ul>
          </div>
      </header><!-- End Header -->
      <div id="mobileSidbar" class="sidebar">
        <img :src="'/assets/img/logo.png'" alt="Logo" class="w-75 ms-4" style="margin-top: -40px"> 
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
              <router-link to="/" class="nav-link text-dark" :class="`${$route.name == 'home' ? 'text-color border-bottom border-info' : ''}`">{{$t('Home')}}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link text-dark" :class="`${$route.name == 'about' ? 'text-color border-bottom border-info' : ''}`">{{$t('About')}}</router-link>
          </li>
          <li class="nav-item" >
            <router-link to="/contact" class="nav-link text-dark" :class="`${$route.name == 'contact' ? 'text-color border-bottom border-info' : ''}`">{{$t('Contact')}}</router-link>
          </li>
          <li class="nav-item" >
            <router-link to="/privacy" class="nav-link text-dark" :class="`${$route.name == 'privacy' ? 'text-color border-bottom border-info' : ''}`">{{$t('Privacy')}}</router-link>
          </li>
          <li class="nav-item" >
            <router-link to="/termsandcondition" class="nav-link text-dark" :class="`${$route.name == 'termsandcondition' ? 'text-color border-bottom border-info' : ''}`">{{$t('Terms and Condition')}}</router-link>
          </li>
        </ul>
      </div>
  </div>
  <router-view/>
 
  <a href="#" @click.prevent="goToTop()" id="btnUp" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
</template>

<script>
import SearchForm from '@/components/SearchForm'
import SwitchLanguage from '@/components/SwitchLanguage'

// import { loadJs } from './everythingJS/until';
// import axios from 'axios';
// import { getContact } from '@/networks/HomePage/routing.service';
import { resource } from '@/networks/domain';
// import { logout } from '@/networks/Auth/login.service'
export default {
  components: {
    SearchForm,
    SwitchLanguage
  },
  data(){
    return {
      data : [],
      resource : resource,
      isTrue : false,
      isLoading : false,
      membershipbenifits : {},
      organizationchart : {},
      desc : '',
    }
  },
  methods: {
    goToTop(){
      $('html, body').animate({
        scrollTop: $("#main").offset().top - 100
      }, 20);
    },
  },
  async created(){
    // await this.getData();
  },
  mounted(){
  },
  updated(){
    $('#cbcDesc').attr('content', this.desc);
  }
}
</script>

<style scoped>

.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
   
    background: linear-gradient(90deg, rgb(246, 249, 250) 0%, rgb(201, 218, 218) 71%);
    
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidebar ul {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  /* font-size: 25px; */
  color: #000;
  display: block;
  transition: 0.3s;
}

.sidebar a {
  text-decoration: none;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}


.openbtn:hover {
  background-color: #444;
}
</style>