<template>
  
  <div v-if="!isLoad" class="container vh-100 position-relative fake-slide mb-5">
    <div class="carousel-inner animated-background position-relative rounded">
      <div class="carousel-item btn-divide-left active">
        <div class="carousel-caption d-none d-md-block"></div>
      </div>
    </div>
    <div class="mt-5 w-100 d-flex justify-content-between">
      <div class="css-dom"></div>
      <div class="css-dom"></div>
      <div class="css-dom"></div>
      <div class="css-dom"></div>
    </div>
  </div>
  <main id="main" v-show="isLoad">
   <div class="container mt-5">
      <section v-if="this.$i18n.locale=='en'">
        <h2 class="text-bold">{{$t('Privacy')}}</h2>
        <div v-html="data.page.privacy.description_en"></div>
      </section>
      <section v-if="this.$i18n.locale=='kh'">
        <h2>{{$t('Privacy')}}</h2>
        <div v-html="data.page.privacy.description_kh"></div>
      </section>
   
   </div>
  </main>
   <!-- ======= Footer ======= -->
   <Footer :data="data.contact" :socials="data.social" />
    
    <!-- End Footer -->
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer'

import axios from 'axios'
import { all } from '@/networks/all/index.service'
// import { loadJs } from '@/components/inc/everythingJS/until'
import {resource} from '@/networks/domain'

export default {
  name: 'PrivacyView',
  components: {
    Footer,
  },
  data(){
    return {
      data : [],
      isLoad : false,
      resource : resource

    }
  },
  methods:{
    async getData(){
      axios.get(all)
        .then((res)=>{
          if(res.status == 200){
            this.data = res.data.data;
            this.isLoad = true;
          }
        })
        .then(()=>{
          $('html, body').animate({
            scrollTop: $(`#${this.whereIs}`).offset().top - 100
          }, 20);
        })
        .catch((err) =>{
          console.log(err);
        })
    },
    handler: function handler(event) {
      console.log(event);
    }
  },
  async created(){
    window.addEventListener('reload', this.handler)
    await this.getData();
  },
  mounted(){
    // this.getData();
  },
  updated(){
    
  }
}
</script>
<style>
.main-item {
  padding: 10px;
  background-color: #fff;
  width: 700px;
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  width: 5%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f8fafc;
  background: linear-gradient(to right, #f2efef 8%, #ececec 12%, #eceeee 33%);
  background-size: 800px 104px;
  height: 500px;
  position: relative;
}

.static-background {
  background-color: #f6f7f8;
  background-size: 800px 104px;
  height: 70px;
  position: relative;
  margin-bottom: 20px;
}

.shared-dom {
  width: 800px;
  height: 110px;
}
.sub-rect {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  float: left;
  margin: 20px 20px 20px 0;
}
.pure-background {
  background-color: #eee;
}

.css-dom:empty {
  width: 280px;
  height: 220px;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
  background-repeat: no-repeat;
  
  background-image:
    radial-gradient(circle 16px, lightgray 99%, transparent 0),
    linear-gradient(lightgray, lightgray),
    linear-gradient(lightgray, lightgray),
    linear-gradient(lightgray, lightgray),
    linear-gradient(lightgray, lightgray),
    linear-gradient(#fff, #fff); 
  
  background-size:
    32px 32px,
    200px 32px,
    180px 32px,
    230px 16px,
    100% 40px,
    280px 100%;
  
  background-position:
    24px 30px,
    66px 30px,
    24px 90px,
    24px 142px,
    0 180px,
    0 0;
}
.fake-slide{
  margin-top: 100px;
}
</style>
