<template>
   <div>
        <!-- <form action="/search" class="d-flex search_box" role="search" @submit="submitData"> -->
        <form action="/search" class="d-flex search_box" role="search" @submit="submitSearch">
            <input type="text" name="code" v-model="tracking" class="form-control border-0 " :placeholder="$t('Enter your tracking code...')" required>
            <!-- <input type="text" name="code" class="form-control border-0 " :placeholder="$t('Enter your tracking code...')" required> -->
            <button type="submit" class="btn btn_search"><i class="fa fa-search"></i></button>
        </form>
    </div>
</template>

 
<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    trackingCode : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource,
      tracking: this.$route.query.code
    }
  },
  methods:{
    submitSearch(e){
        // e.preventDefault();
        setTimeout(() => {
          this.$router.push({name: 'search', params: { "id": this.tracking }})
        }, 100);
    }
  }
}
</script>

<style scoped>
    
</style>
