<template>
  <!-- ======= Clients Section ======= -->

<!-- <div class="container" data-aos="fade-up"> -->

  <!-- <div class="clients-slider swiper">
    <div class="swiper-wrapper align-items-center">
      <div 
        v-for="data in partners"
        :key="data.id"
        class="swiper-slide">
        <a :href="`${data.link != '' || data.link != null ? data.link : '#'}`" :target="`${data.link != '' || data.link != null ? '_blank' : '_self'}`">
          <img :src="resource + data.photo" class="img-fluid" alt="">
        </a>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</div> -->

<section id="clients" class="clients section-bg">
    <div class="container" data-aos="zoom-in">
        <div class="row">

            <div class="col-md-2" v-if="partners.length == 4"></div>
            <div class="col-lg-2 col-md-4 col-3 d-flex align-items-center justify-content-center"  v-for="data in partners" :key="data.id">
                <img :src="resource + data.photo" class="img-fluid" alt="Partner">
            </div>
        </div>
    </div>

</section><!-- End Clients Section -->
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    partners : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>

<style>

</style>